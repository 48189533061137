<template>
	<Layout>
		<Header />
		<div>
			Hello World Validator
		</div>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Api from '@/services/api'
import { responsive } from '@/mixins/responsive'
import { errorToast } from '@/mixins/toast'

export default {
	name: 'Validator',
	mixins: [responsive],
	components: {
		Layout,
		Header
	},
    mounted() {
        this.findAll()
    },
	methods: {
        findAll() {
            Api.get('validator/findAll')
                .then(({ data }) => {
                    console.log(data)
                })
                .catch((err) => {
                    console.log(err)
                    errorToast(this.$t('alerts.load_error'))
                })
        }
	}
}
</script>
